<template>
  <Swiper
    :modules="modules"
    :slides-per-view="1"
    :spaceBetween="0"
    :autoHeight="true"
    :speed="200"
    :effect="'fade'"
    class="swiper-fade relative m-0 mx-auto h-auto w-full p-0 text-left"
  >
    <SwiperSlide v-for="(testimonial, idx) in data" :key="idx" class="bg-white">
      <div class="rounded-br-[240px] bg-green-80 text-white lg:rounded-br-[400px]">
        <div class="relative px-4 pb-28 pt-10 lg:p-20 lg:pb-40 lg:pr-52">
          <div
            class="mb-6 font-gt-flexa-light text-xl lg:mb-8 lg:text-3xl"
            v-html="testimonial.body"
          ></div>
          <div v-if="testimonial.name" class="mb-2 text-base lg:mb-0 lg:text-xl">
            {{ testimonial.name }}
          </div>
          <div v-if="testimonial.company" class="text-xl-semibold lg:text-2xl">
            {{ testimonial.company }}
          </div>
          <div
            v-if="testimonial.logo"
            class="my-6 flex h-[70px] w-[216px] justify-center border border-black bg-white2 text-center lg:absolute lg:bottom-8 lg:right-0 lg:h-[112px] lg:w-[336px] lg:max-w-none"
          >
            <Image
              v-if="testimonial.logo"
              class="mx-auto h-fit max-h-[60px] w-fit max-w-[120px] lg:max-h-[95px] lg:max-w-[185px]"
              sizes="(min-width: 1040px) 185px, 120px"
              :src="testimonial.logo.responsiveImage ? testimonial.logo.responsiveImage?.src : testimonial.logo.url"
              :srcset="testimonial.logo.responsiveImage ? testimonial.logo.responsiveImage?.webpSrcSet : undefined"
              :alt="testimonial.logo.alt ? testimonial.logo.alt : ''"
              :width="200"
              :height="120"
            />
          </div>
        </div>
      </div>
    </SwiperSlide>
    <TestimonialsNav
      v-if="data.length > 1"
      class="absolute bottom-10 left-4 top-auto z-1 h-10 lg:bottom-20 lg:left-20"
    />
  </Swiper>
</template>

<script setup lang="ts">
import { EffectFade, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'
import 'swiper/css/effect-fade'

defineProps({
  data: {
    type: Array,
    default: () => []
  }
})

const modules = [EffectFade, A11y]
</script>
