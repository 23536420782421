<template>
  <div>
    <Button
      :isType="swiper.isBeginning ? 'circle-secondary' : 'circle'"
      :disabled="swiper.isBeginning"
      :title="$t('swiper.previous')"
      class="xl:!h-16 xl:!w-16"
      :icon="{ name: 'arrow-left-long', color: 'white', size: 'circle' }"
      @click="swiper.slidePrev()"
    />
    <Button
      :isType="swiper.isEnd ? 'circle-secondary' : 'circle'"
      :disabled="swiper.isEnd"
      :title="$t('swiper.next')"
      class="ml-4 xl:ml-10 xl:!h-16 xl:!w-16"
      :icon="{ name: 'arrow-right-long', color: 'white', size: 'circle' }"
      @click="swiper.slideNext()"
    />
  </div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const swiper = useSwiper()
</script>
